import React, { useEffect } from 'react';
import './card.scss';
import { connect } from 'react-redux';
import { setFavoriteProducts } from '../../redux/actions';
import { useState } from 'react';
import { priceFormat } from '../../utils/priceFormatter';

function ToomarCard({ value , isSuggest, inMainPage, isMostSold, ...props }: any) {
    const [isFavorite, setIsFavorite] = useState(false);
    useEffect(() => {
        if(props.favoriteProducts && props.favoriteProducts.length > 0) {
            for(let i = 0; i <= props.favoriteProducts?.length; i++){
                if(value.id === props.favoriteProducts[i]?.id) {
                    setIsFavorite(true);
                }
            } 
        }
    }, [])
    
    return (
        <a href={`/product/${value?.id}`} style={{height: "380px"}} target="_blank">
            <div className={'toomarCardContainer'} style={isMostSold ? {boxShadow: "none", backgroundColor: "black"}: {}}>
                <img src={value?.cover} alt={'کارت ترحیم'}/>
                <div className='overflowTitle'>
                    <p style={inMainPage === true && isMostSold ? { fontSize: 14, color: "white" } : 
                            isMostSold ? {color: "white"} : inMainPage ? {fontSize: 14} : {}}>
                        {value?.title}
                    </p>
                </div>
                <div className={'footerCards'}>
                    <div className='productCode'>
                        <span style={isMostSold ? {color: "white"} : {color: "gray"}}>{value.code}</span>
                    </div>
                    <div className={'price'}>
                        <span style={isMostSold ? {color: "white"} : {}}>
                            تومان
                        </span>
                        <span style={isMostSold ? {color: "white"} : {}}>
                            {value?.discount != 0 ?
                            priceFormat(value?.price * ((100 - value?.discount)/100)) : 
                            priceFormat(value?.price)}
                        </span>
                        {value?.discount != 0 ?
                            <span style={isMostSold ? {color: "white"} : {}}>
                                {priceFormat(value?.price)}
                            </span>
                        : null} 
                    </div>
                </div>
                {value?.discount != 0 && isSuggest != true ?
                    <div className={'offPercent'}>
                        {value.discount}%
                    </div> 
                : null }
                {isSuggest == true ?
                <div className={'suggestContainer'}>
                    <p>
                        پیشنهاد شگفت انگیز
                    </p>
                </div>
                : null} 
                <div className={"favoriteContainer"}>
                    <img src={require(isFavorite ? "../../assets/icons/favorite.png" : "../../assets/icons/non_favorite.png")}/>
                </div>
            </div>
        </a>
    )
}

const mapStateToProps = (state: any) => ({
    favoriteProducts: state.counter.favoriteProducts
})

export default connect(mapStateToProps, { setFavoriteProducts })(ToomarCard);