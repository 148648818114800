import React, { useState } from 'react';
import { sentTicket } from '../../redux/actions';
import './registerComplaints.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import MainContainer from '../containers/mainContainer';
import ToomarInput from '../utils/toomarInput';
import ToomarTextarea from '../utils/ToomarTextarea';
import Title from '../utils/title';
import FollowUsInSocials from '../utils/followUpInSocials';

function RegisterComplaints({...props}) {
    
    const [name, setName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');

    const handleSentTicket = () => {
        let data = {
            "phone_number": phoneNumber,
            "content": comment,
            "email": email,
            "name": name + " " + familyName,
            "subject": "ثبت شکایت"
        }
        props.sentTicket(data, () => {
            toast("با موفقیت ثبت شد!");
            setName('');
            setFamilyName('');
            setPhoneNumber('');
            setEmail('');
            setComment('');
            },
            () => toast("مشکلی پیش آمده!"))
    }
    
    return (
        <MainContainer>
            <ToastContainer />
            <Title
            goldText={''}
            blackText={'ثبت شکایات'}/>
            <div className={'registerComplaintsContainer'}>
                <p>
                    کاربر گرامی تیم طومار همه ی تلاش خودرا میکند تا بهترین خدمات را به شما ارائه دهد ،در صورتی که شکایتی از خدمات طومار دارید برای ما ارسال نمایید تا همکاران ما در اسرع وقت باشما تماس گرفته و مشکل را مرتفع نمایند هدف شرکت طومار جلب رضایت شما عروس داماد های عزیز است.
                </p>
                <div>
                    <ToomarInput
                    title={'نام'}
                    inputValue={name}
                    onChange={(e: any) => setName(e.target.value)}
                    />
                    <ToomarInput
                    title={'نام خانوادگی'}
                    inputValue={familyName}
                    onChange={(e: any) => setFamilyName(e.target.value)}
                    />
                </div>
                <div>
                    <ToomarInput
                    title={'شماره موبایل'}
                    maxLength={11}
                    inputValue={phoneNumber}
                    errorMessage={phoneNumber.length != 11 ? "تلفن همراه باید ۱۱ رقمی باشد" : ""}
                    onChange={(e: any) => setPhoneNumber(e.target.value)}
                    />
                    <ToomarInput
                    title={'آدرس ایمیل'}
                    inputValue={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <ToomarTextarea
                    title={'نظر'}
                    inputValue={comment}
                    onChange={(e: any) => setComment(e.target.value)}
                    />
                </div>
                <div>
                    <button onClick={handleSentTicket}>
                        ارسال نظر
                    </button>
                </div>
                <FollowUsInSocials/>
            </div>
                
        </MainContainer>
    )
}

export default connect(null, { sentTicket })(RegisterComplaints);