import React, { useEffect } from 'react';
import './card.scss';
import { connect } from 'react-redux';
import { setFavoriteProducts } from '../../redux/actions';
import { useState } from 'react';
import { priceFormat } from '../../utils/priceFormatter';

function ToomarCardGallery({value , isSuggest, inMainPage, noShadow, ...props}: any) {
    const [isFavorite, setIsFavorite] = useState(false);
    useEffect(() => {
        if(props.favoriteProducts && props.favoriteProducts.length > 0) {
            for(let i = 0; i <= props.favoriteProducts?.length; i++){
                if(value.id === props.favoriteProducts[i]?.id) {
                    setIsFavorite(true);
                }
            } 
        }
    }, [props.favoriteProducts])

    const handleIsFavorite = () => {
        if(isFavorite) {
            let index = props.favoriteProducts.findIndex((item:any) => item.id == value?.id);
            let locObject = [...props.favoriteProducts];
            locObject.splice(index, 1);
            props.setFavoriteProducts([...locObject]);
            setIsFavorite(false);
        }
        else {
            props.setFavoriteProducts([...props.favoriteProducts, value])
        }
    }

    return (
        <a>
            <div className={'toomarCardGalleryContainer'} style={noShadow ? { boxShadow: "none" }: {}}>
                <div className={"favoriteContainer"} style={{ cursor: "pointer" }} onClick={handleIsFavorite}>
                    <img src={require(isFavorite ? "../../assets/icons/favorite.png" : "../../assets/icons/non_favorite.png")}/>
                </div>
                <a href={`/product/${value?.id}`} target="_blank">
                    <img src={value?.cover} alt={'کارت ترحیم'}/>
                    <div className='overflowTitle'>
                        <p style={inMainPage === true ? { fontSize: 14 } : {}}>
                            {value?.title}
                        </p>
                    </div>
                    <div className={'footerCards'}>
                        <div className='productCode'>
                            <span style={{color: "black", marginRight: "10px"}}>{value.code}</span>
                        </div>
                        <div className={'price'}>
                            <span>
                                تومان
                            </span>
                            <span>
                                {value?.discount != 0 ?
                                priceFormat(value?.price * ((100 - value?.discount)/100)) : 
                                priceFormat(value?.price)}
                            </span>
                            {value?.discount != 0 ?
                                <span>
                                    {priceFormat(value?.price)}
                                </span>
                            : null} 
                        </div>
                    </div>
                    {value?.discount != 0 && isSuggest != true ?
                        <div className={'offPercent'}>
                            {value.discount}%
                        </div> 
                    : null }
                    {isSuggest == true ?
                    <div className={'suggestContainer'}>
                        <p>
                            پیشنهاد شگفت انگیز
                        </p>
                    </div>
                    : null} 
                </a>
                
            </div>
        </a>
    )
}

const mapStateToProps = (state: any) => ({
    favoriteProducts: state.counter.favoriteProducts
})

export default connect(mapStateToProps, { setFavoriteProducts })(ToomarCardGallery);;