import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllProducts } from '../../redux/actions';
import { Link } from 'react-router-dom';
import Title from '../utils/title';
import ToomarUnderlineSmooth from "../../assets/icons/toomarUnderlineSmooth.svg";
function ToomarGallery({...props}: any) {

    const [menuItem, setMenuItems] = useState<any>();
    const [whichMenu, setWhichMenu] = useState(106);

    useEffect(() => {
        props.getAllProducts({category: `${whichMenu}`}, (data: any) => setMenuItems(data.data.results));
    }, [])

    const handleGetWhichMenu = (id: number) => {
        setWhichMenu(id);
        setMenuItems([])
        props.getAllProducts({category: `${id}`, ordering:"-score"}, (data: any) => setMenuItems(data.data.results));
    }

    return (
        <div className={'toomarGalleryContainer'}>
            <Title goldText={''} blackText={'گالری محصولات ترحیم، مکه ای و ...'}/>
            <div>
                <div>
                    {props.galleryCategories.map((item: any) => 
                        <div onClick={() => handleGetWhichMenu(item.id)}>
                            <p className={whichMenu == item.id ? 'isActive' : 'isNotActive'}>
                                {item.name}
                            </p>
                            {whichMenu == item.id ? <img src={ToomarUnderlineSmooth} alt={'underline'}/> : null}
                        </div>
                    )}
                </div>
                <div>
                    {menuItem && [...menuItem]?.splice(0, 4).map((item: any, index: number) => (
                        <div>
                            <Link to={`/product/${item.id}`}>
                                {item?.cover && <img src={item?.cover} alt={'کارت ترحیم'}/>}
                                <div>
                                    <p>
                                        {item?.category_name}
                                    </p>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default connect(null, { getAllProducts })(ToomarGallery);