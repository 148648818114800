import ToomarUnderlineSmooth from "../../assets/icons/toomarUnderlineSmooth.svg";

export default function GalleryOrder({ onClick, text, active, icon }: any) {
  return (
    <div onClick={onClick}>
      <p className={active ? "isActive" : "isNotActive"}>{text}</p>
      {active && <img src={ToomarUnderlineSmooth} alt={"کارت ترحیم"} /> }
      <div></div>
    </div>
  );
}
