import React, { useEffect, useState } from 'react';
import './buyProcess.scss';
import Tick from '../../assets/icons/tick.svg';
import SelectText from './selectText';
import SelectTitle from './selectTitle';
import AddData from './addData';
import AcceptData from './acceptData';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { setFinishedProductDetails } from '../../redux/actions';
import MainContainer from '../containers/mainContainer';


function BuyProcess({...props}: any) {
    const [step, setStep] = useState(1);
    const params : any = useParams();
    const [chooseText, setChooseText] = useState<any>('');
    const [customText, setCustomText] = useState(''); 
    const [typeOfTitle, setTypeOfTitle] = useState<any>('');
    const [typeOfPrintForLatin, setTypeOfPrintForLatin] = useState<any>('');
    const [typeOfPlan, setTypeOfPlan] = useState(0);
    const [typeOfPrint, setTypeOfPrint] = useState(2);
    const [personName, setPersonName] = useState('');
    const [nickName, setNickName] = useState('');
    const [personFamilyName, setPersonFamilyName] = useState('');
    const [startHour, setStartHour] = useState(12);
    const [startMin, setStartMin] = useState(0);
    const [endHour, setEndHour] = useState(12);
    const [endMin, setEndMin] = useState(0);
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [address, setAddress] = useState('');
    const [forWhat, setForWhat] = useState('');
    const [description, setDescription] = useState('');
    const [isEdit, setIsEdit] = useState<any>('');
    const [typeOfBegayi, setTypeOfBegayi] = useState(0);
    const [justThisStep, setJustThisStep] = useState(false);
    const search = useLocation().search;
    const queryStep : any = new URLSearchParams(search).get('step');
    useEffect(() => {
        for(let i = 0; i < props.finishedProductDetails.length; i ++) {
            if(props.buyingProducts[params.id - 1] && props.finishedProductDetails[i].product == props.buyingProducts[params.id - 1].data.id) {
                setIsEdit(i);
                setPersonName(props.finishedProductDetails[i].info.person_name);
                setNickName(props.finishedProductDetails[i].info.nick_name);
                setForWhat(props.finishedProductDetails[i].besarfe);
                setDescription(props.finishedProductDetails[i].description)
                setPersonFamilyName(props.finishedProductDetails[i].info.person_familyname);
                setDay(props.finishedProductDetails[i].info.celeb_date.day)
                setMonth(props.finishedProductDetails[i].info.celeb_date.month)
                setYear(props.finishedProductDetails[i].info.celeb_date.year)
                setStartHour(props.finishedProductDetails[i].info.timeFrom.hour)
                setStartMin(props.finishedProductDetails[i].info.timeFrom.minute)
                setEndHour(props.finishedProductDetails[i].info.timeTill.hour)
                setEndMin(props.finishedProductDetails[i].info.timeTill.minute)
                setAddress(props.finishedProductDetails[i].info.talar_address)
                setChooseText(props.finishedProductDetails[i].text === undefined ? '' : props.finishedProductDetails[i].text === null ? null : {"id": props.finishedProductDetails[i].text})
                setCustomText(props.finishedProductDetails[i].text_custom)
                setTypeOfTitle(props.finishedProductDetails[i].title === undefined ? '' : {"id": props.finishedProductDetails[i].title})
                setTypeOfPrint(parseInt(props.finishedProductDetails[i].koob) < 5 ? 1 : 2)
                setTypeOfPlan(parseInt(props.finishedProductDetails[i].koob))
                setTypeOfPrintForLatin(parseInt(props.finishedProductDetails[i].latin))
            }
        }
        if(props.buyingProducts[params.id - 1] && props.buyingProducts[params.id - 1].data && props.buyingProducts[params.id - 1].data.is_koob == true) {
            setTypeOfPrint(2);
        }
        if(queryStep==3) {
            setStep(3)
        }
        if(props.buyingProducts[params.id - 1].data.is_tarhim === false) {
            setStep(1)
        }
        if(props.buyingProducts[params.id - 1].data.is_card_mourning === true && props.buyingProducts[params.id - 1].data.is_tarhim) {
            setStep(2)
        }
        if(props.buyingProducts[params.id - 1].data.is_tarhim === undefined || (props.buyingProducts[params.id - 1].data.is_tarhim && props.buyingProducts[params.id - 1].data.is_digital_mourning)) {
            setStep(3);
        }
    }, props.buyingProducts)
    const handleSubmitDetails = () => {
        var data =  {
            "type": props.buyingProducts[params.id - 1].data.is_card_mourning == true ? 'card_mourning' : props.buyingProducts[params.id - 1].data.is_digital_mourning == true ? 'digital_mourning' : 'other',
            "product": props.buyingProducts[params.id - 1].data.id,
            "text": chooseText != '' && chooseText != null ? chooseText.id : chooseText === null ? null : '',
            "text_custom": customText,
            "title": typeOfTitle != '' ? typeOfTitle.id : '',
            "count": props.buyingProducts[params.id - 1].count,
            "options": props.buyingProducts[params.id - 1].options,
            "koob": typeOfPrint === 2 && typeOfPlan >= 5 ? typeOfPlan : typeOfPrint === 2 ? typeOfPlan : typeOfPlan, //(0, 'مشکی'), (1, 'قهوه‌ای'), (2, 'قرمز'), (3, 'زرد'), (4, 'سفید'), (5, 'طلا کوب'), (6, 'نقره کوب')
            "latin": typeOfPrintForLatin,
            "description": description,
            "info": {
                "titrazh": "",
                "person_name": personName,
                "person_familyname": personFamilyName,
                "nick_name": nickName,
                "besarfe": forWhat,
                "typeOfMarasem": typeOfBegayi == 0 ? "ختم و خاکسپاری" : typeOfBegayi == 1 ? "مراسم هفتم" : typeOfBegayi == 2 ? "مراسم چهل" : typeOfBegayi == 3 ? "اولین سالگرد" : "سالگرد",
                "celeb_date": {
                    "day": day,
                    "month": month,
                    "year": year
                },
                "timeFrom": {
                    "hour": startHour,
                    "minute": startMin,
                    "second": 0
                },
                "timeTill": {
                    "hour": endHour,
                    "minute": endMin,
                    "second": 0
                },
                "talar_address": address
            }
        }
        if(isEdit !== '') {
            let locFinishedProducts = [];
            locFinishedProducts = [...props.finishedProductDetails];
            locFinishedProducts[isEdit] = data;
            props.setFinishedProductDetails([...locFinishedProducts]);
            window.location.replace('/cart');
        }
        else {
            props.setFinishedProductDetails([...props.finishedProductDetails, data]);
            window.location.replace('/cart');
        }
        
    }
    return (
        <MainContainer>
            <div style={{position: "absolute", top: 0}}/>
            <ToastContainer/>
            <div className={'buyProcessContainer'}>
                {/* <p>
                    ثبت اطلاعات
                </p> */}
                {props.buyingProducts[params.id - 1].data.is_tarhim !== undefined ?
                <>
                    <div className={(props.buyingProducts[params.id - 1]?.data?.is_card_mourning === true && props.buyingProducts[params.id - 1].data.is_tarhim) ||
                        (props.buyingProducts[params.id - 1]?.data?.is_digital_mourning === true && !props.buyingProducts[params.id - 1].data.is_tarhim)  ? 'progressBarContainerForBuyProcessDigital' : 'progressBarContainerForBuyProcess'}>
                        <div>
                            <div style={step >= 2 ? {backgroundColor: '#39B93A'} : {}}>
                                <img src={Tick} alt={'tick'}/>
                            </div>
                            <div style={step >= 2 ? {backgroundColor: '#39B93A'} : {}}/>
                            {props.buyingProducts[params.id - 1]?.data?.is_card_mourning === true && props.buyingProducts[params.id - 1].data.is_tarhim ? null :
                            <>
                                <div style={step >= 3 ? {backgroundColor: '#39B93A'} : {}}>
                                    <img src={Tick} alt={'tick'}/>
                                </div>
                                <div style={step >= 3 ? {backgroundColor: '#39B93A'} : {}}/>
                            </>
                            }
                            <div style={step >= 4 ? {backgroundColor: '#39B93A'} : {}}>
                                <img src={Tick} alt={'tick'}/>
                            </div>
                            <div style={step >= 4 ? {backgroundColor: '#39B93A'} : {}}/>
                            <div style={step >= 5 ? {backgroundColor: '#39B93A'} : {}}>
                                <img src={Tick} alt={'tick'}/>
                            </div>
                        </div>
                        <div>
                            {props.buyingProducts[params.id - 1]?.data?.is_card_mourning === true && !props.buyingProducts[params.id - 1].data.is_tarhim ? 
                            <p>
                                انتخاب متن
                            </p>
                            : null}
                            {/* {props.buyingProducts[params.id - 1]?.data?.is_digital_mourning == true ? null : */}
                            {/* <> */}
                            {
                                props.buyingProducts[params.id - 1]?.data?.is_digital_mourning === true && !props.buyingProducts[params.id - 1].data.is_tarhim ? null :
                                <p>
                                    {props.buyingProducts[params.id - 1].data.is_tarhim || props.buyingProducts[params.id - 1].data.is_tarhim ? "تیتر و نوع چاپ" : "نوع چاپ"}
                                </p>
                            }
                            
                            {/* </> */}
                            {/* } */}
                            <p>
                                ورود اطلاعات کارت
                            </p>
                            <p>
                                تایید اطلاعات
                            </p>
                        </div>
                    </div>
                </>
                : 
                    <div className={"twpStepProgress"}>
                        <div>
                            <div style={step >= 4 ? {backgroundColor: '#39B93A'} : {}}>
                                <img src={Tick} alt={'tick'}/>
                            </div>
                            <div style={step >= 4 ? {backgroundColor: '#39B93A'} : {}}/>
                            <div style={step >= 5 ? {backgroundColor: '#39B93A'} : {}}>
                                <img src={Tick} alt={'tick'}/>
                            </div>
                        </div>
                        <div>
                            <p>
                                ورود اطلاعات کارت
                            </p>
                            <p>
                                تایید اطلاعات
                            </p>
                        </div>
                    </div>
                }
                {step == 1 ?
                <SelectText 
                chooseText={chooseText}
                setChooseText={(value: any) => setChooseText(value)}
                justThisStep={justThisStep}
                setJustThisStep={(value: any) => setJustThisStep(value)}
                customText={customText}
                setCustomText={(value: any) => setCustomText(value)}
                setStep={(value: any) => {setStep(value); window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}/> 
                :
                step == 2 ?
                <SelectTitle 
                typeOfTitle={typeOfTitle}
                setTypeOfTitle={(value: any) => setTypeOfTitle(value)}
                typeOfPrintForLatin={typeOfPrintForLatin}
                setTypeOfPrintForLatin={(value: any) => setTypeOfPrintForLatin(value)}
                typeOfPlan={typeOfPlan}
                setTypeOfPlan={(value: any) => setTypeOfPlan(value)}
                typeOfPrint={typeOfPrint}
                setTypeOfPrint={(value: any) => setTypeOfPrint(value)}
                justThisStep={justThisStep}
                setJustThisStep={(value: any) => {setJustThisStep(value); window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}
                setStep={(value: any) => {setStep(value); window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}/> 
                :
                step == 3 ?
                <AddData 
                typeOfBegayi={typeOfBegayi}
                setTypeOfBegayi={(value: any) => setTypeOfBegayi(value)}
                personFamilyName={personFamilyName}
                setPersonFamilyName={(value: any) => setPersonFamilyName(value)}
                nickName={nickName}
                setNickName={(value: any) => setNickName(value)}
                personName={personName}
                setPersonName={(value: any) => setPersonName(value)}
                startHour={startHour}
                setStartHour={(value: any) => setStartHour(value)}
                startMin={startMin}
                setStartMin={(value: any) => setStartMin(value)}
                endHour={endHour}
                setEndHour={(value: any) => setEndHour(value)}
                endMin={endMin}
                setEndMin={(value: any) => setEndMin(value)}
                address={address}
                setAddress={(value: any) => setAddress(value)}
                forWhat={forWhat}
                setForWhat={(value: any) => setForWhat(value)}
                description={description}
                setDescription={(value: any) => setDescription(value)}
                day={day}
                setDay={(value: any) => setDay(value)}
                month={month}
                setMonth={(value: any) => setMonth(value)}
                year={year}
                setYear={(value: any) => setYear(value)}
                justThisStep={justThisStep}
                setJustThisStep={(value: any) => {setJustThisStep(value); window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}
                setStep={(value: any) => {setStep(value); window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}
                onFinished={handleSubmitDetails}/> 
                : 
                <AcceptData
                chooseText={chooseText}
                customText={customText}
                typeOfTitle={typeOfTitle}
                typeOfPrintForLatin={typeOfPrintForLatin}
                typeOfPlan={typeOfPlan}
                typeOfPrint={typeOfPrint}
                personName={personName}
                personFamilyName={personFamilyName}
                forWhat={forWhat}
                startHour={startHour}
                startMin={startMin}
                endHour={endHour}
                endMin={endMin}
                address={address}
                day={day}
                nickName={nickName}
                month={month}
                year={year}
                justThisStep={justThisStep}
                setJustThisStep={(value: any) => setJustThisStep(value)}
                onFinished={handleSubmitDetails} 
                product={props.buyingProducts[params.id - 1]}
                setStep={(value: any) => {setStep(value); window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}/>}
            </div>
        </MainContainer>
    )
}

const mapStateToProps = (state: any) => ({
    buyingProducts: state.counter.buyingProducts,
    finishedProductDetails: state.counter.finishedProductDetails
})

export default connect(mapStateToProps, { setFinishedProductDetails })(BuyProcess);