import React, { useEffect, useRef, useState } from 'react';
import Filter from '../../assets/icons/filter.svg';
import grayFilter from '../../assets/icons/grayFilter.svg';
import Logo from '../../assets/icons/toomarLogo.svg';
import Search from '../../assets/icons/search.svg';
import Bag from '../../assets/icons/bag.svg';
import Telegram from '../../assets/icons/telegram.svg';
import Whatsapp from '../../assets/icons/whatsapp.svg';
import Instagram from '../../assets/icons/instagram.svg';
import TelegramBlack from '../../assets/icons/telegramBlack.svg';
import WhatsappBlack from '../../assets/icons/whatsappBlack.svg';
import InstagramBlack from '../../assets/icons/instagramBlack.svg';
import Hamburger from '../../assets/icons/hamburger.svg';
import Cart from '../../assets/icons/cart.svg';
import Close from '../../assets/icons/circleClose.svg';
import './menu.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { getCategoriesTree, getSearchResults } from '../../redux/actions';
import WeddingDigitalCard from '../../assets/icons/weddingDigitalCard.svg';
import SpecialGift from '../../assets/icons/specialGift.svg';
import WeddingGift from '../../assets/icons/weddingGift.svg';
import WeddingCardBlack from '../../assets/icons/weddingCardBlack.svg';
import Office from '../../assets/icons/office.svg';
import MemorialBoard from '../../assets/icons/memorialBoard.svg';
import { categoryUrl } from '../../utils/urlFormatter';
import ToomarCard from '../cards/toomarCard';


const useStyles = makeStyles((theme: any) => ({
    root: {
        width: '500px',
        // height: 'var(--dialog-height)',
        borderRadius: '24px',
        "@media (max-width:800px)": { 
            width: '100%',
            margin: '0px !important'
        },
    }
}));

function Menu(props: any) {
    const [openMenu, setOpenMenu] = useState(false);
    const [cartModal, setCartModal] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [openMegaMenu, setOpenMegaMenu] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const mobileMenuRef = useRef<HTMLDivElement | null>(null);
    const classes = useStyles();
    const [searchText, setSearchText] = useState('');
    const [searchedResults, setSearchResults] = useState([]);
    const [searchedCategories, setSearchCategories] = useState([]);
    const [allCategories, setAllCategories] = useState([])
    const [firstChildCategory, setFirstChildCategory] = useState<any>({});
    const [showCategories, setShowCategories] = useState(false);
    const [inApi, setInApi] = useState(false);
    const navigate = useNavigate();

    const handleGetResults = async(search: any) => {
        if(search.target.value == "") {
            setSearchText("")
            setSearchResults([]);
            setInApi(false);
        }
        else {
            setInApi(true);
            setSearchText(search.target.value.toUpperCase())
            props.getSearchResults(search.target.value.toUpperCase(), (data: any) => {
                setSearchResults(data.data.by_code);
                setSearchCategories(data.data.by_category);
            })
            setInApi(false);
        }
    }

    useEffect(() => {
        mobileMenuRef.current?.scrollIntoView({ behavior: 'smooth' })
        props.getCategoriesTree("domain=1", (data: any) => setAllCategories(data.data.result.children));
    }, [mobileMenuRef, props])
    
    return (
        <>
        <Dialog
            open={cartModal}
            hideBackdrop={false}
            onClose={() => setCartModal(false)}
            PaperProps={{
                className: classes.root
            }}
            >
                <div className={'noBuyingProductContainer'}>
                    <img src={Cart}/>
                    <p>
                        سبد خرید شما خالی است
                    </p>
                    <p>
                        از گالری محصولات دیدن کنید
                    </p>
                    <Link to={'/gallery/'}>
                        <button>
                            رفتن به گالری محصولات
                        </button>
                    </Link>
                </div>
            </Dialog>
            <div ref={menuRef} className={'menuContainer'}>
                <div>
                    <a href={'http://t.me/toomar_tarhim/'} target={'_blank'}>
                        <img src={Telegram} alt={'telegram'}/>
                    </a>
                    <a href={'http://wa.me/989057837887'} target={'_blank'}>
                        <img src={Whatsapp} alt={'whatsapp'}/>
                    </a>
                    <a href={'https://www.instagram.com/toomar_tarhim/'} target={'_blank'}>
                        <img src={Instagram} alt={'instagram'}/>
                    </a>
                </div>
                <div>
                    <div>
                        <Link to={'/'}>
                            <img src={Logo} alt={'logo'}/>
                        </Link>
                        <div onMouseOver={() => setOpenMegaMenu(true)} onMouseLeave={() => {setOpenMegaMenu(false); setFirstChildCategory({})}}>
                            <img src={Filter} alt={'filter'}/>
                            <span>
                                دسته بندی
                            </span>
                            {openMegaMenu ?
                            <div>
                                <div>
                                    <div>
                                        <img src={grayFilter}/>
                                        <span>
                                            دسته بندی محصولات
                                        </span>
                                    </div>
                                    {allCategories.map((item: any, index: number) => (
                                        <div onClick={() => {navigate(categoryUrl(item))}} className={firstChildCategory.name == item.name ? 'active' : ''} onMouseOver={() => setFirstChildCategory(item)}>
                                            {item.icon ? <img style={{width: 24, height: 24}} src={`https://toomar.co${item.icon}`}/> : <img src={index == 0 ? WeddingCardBlack : index == 1 ? WeddingDigitalCard : index == 2 ? WeddingGift : index == 3 ? Office : index == 4 ? MemorialBoard : SpecialGift}/>}
                                            <span>
                                                {item.name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                {firstChildCategory.name != undefined ?
                                <div>
                                    <span>
                                        {`همه دسته‌بندی ${firstChildCategory.name}`}
                                    </span>
                                    {firstChildCategory.children.length > 0 && firstChildCategory.children.map((item: any) => (
                                        <div>
                                            <div onClick={() => {navigate(categoryUrl(item))}} >
                                                <p>.</p>
                                                <p>
                                                    {item.name}
                                                </p>
                                            </div>
                                            {item.children.length > 0 ? 
                                            <div>
                                                {item.children.map((item: any) => (
                                                    <p onClick={() => {navigate(categoryUrl(item))}}>
                                                        {item.name}
                                                    </p>
                                                ))}
                                            </div>
                                            : null}
                                        </div>
                                    ))}
                                </div>
                                : null }
                            </div>
                            : null}
                        </div>
                        <Link to={'/gallery/'}>
                            <div>
                                <span>
                                    گالری محصولات ترحیم
                                </span>
                            </div>
                        </Link>
                        <Link to={'https://toomar.co/'}>
                            <div>
                                <span>
                                    اقلام عروسی
                                </span>
                            </div>
                        </Link>
                        <Link to={'/magazine/'}>
                            <div>
                                <span>
                                    مجله ی طومار
                                </span>
                            </div>
                        </Link>
                        <Link to='/about-us/'>
                            <div>
                                <span>
                                    درباره ما
                                </span>
                            </div>
                        </Link>
                        <Link to='/contact-us/'>
                            <div>
                                <span>
                                    تماس با ما
                                </span>
                            </div>
                        </Link>
                        <Link to='/followup-order/'>
                            <div>
                                <span>
                                    پیگیری سفارش
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div>
                        {
                            openSearch ? 
                            <div className='searchContainer'>
                                <input placeholder='کد یا دسته‌بندی مورد نظر را وارد کنید' value={searchText} onChange={(e) => handleGetResults(e)}/>
                                <img onClick={() => {setSearchResults([]); setSearchText(''); setOpenSearch(false)}} src={Close}/>
                                {(searchedResults.length > 0 || searchedCategories.length > 0) && searchText != '' && !inApi ?
                                <div className='byCodeContainer'>
                                    {searchedResults.length > 0 &&
                                        <p>براساس کد</p>
                                    }
                                    <div>
                                        {searchedResults.length > 0 && searchedResults.map(item => (
                                            <ToomarCard value={item}/>
                                        ))}
                                    </div>
                                    {searchedCategories.length > 0 &&
                                        <p>براساس دسته بندی</p>
                                    }
                                    <div>
                                        {
                                            searchedCategories.length > 0 &&
                                                searchedCategories.map((item: any) => (
                                                    <div>
                                                        <p onClick={() => {navigate(categoryUrl(item))}}>
                                                            {item.name}
                                                        </p>
                                                    </div>
                                                ))
                                        }
                                    </div>
                                </div>
                                : null }
                                
                            </div>
                            : 
                            <div onClick={() => setOpenSearch(true)}>
                                <img src={Search} alt={'search'}/>
                            </div>
                        }
                        <Link to={'/favorite/'}>
                            <div>
                                {props.favoriteProducts?.length > 0 && 
                                    <p>
                                    {props.favoriteProducts?.length}  
                                    </p>
                                }
                                <img src={require("../../assets/icons/non_favorite.png")} alt={'bag'}/>
                            </div>
                        </Link>
                        {props.buyingProducts?.length > 0 ? 
                        <Link to={'/cart/'}>
                            <div>
                                <p>
                                  {props.buyingProducts.length}  
                                </p>
                                <img src={Bag} alt={'bag'}/>
                            </div>
                        </Link>
                        : 
                        <div onClick={() => setCartModal(true)}>
                            <img src={Bag} alt={'bag'}/>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div ref={mobileMenuRef} className={'menuContainerMobile'}>
                <div>
                    <Link to={'/'}>
                        <img src={Logo} alt={'logo'}/>
                    </Link>
                    <div>
                        <a href={'http://t.me/toomar_tarhim/'} target={'_blank'}>
                            <img src={TelegramBlack} alt={'telegram'}/>
                        </a>
                        <a href={'http://wa.me/989057837887'} target={'_blank'}>
                            <img src={WhatsappBlack} alt={'whatsapp'}/>
                        </a>
                        <a href={'https://www.instagram.com/toomar_tarhim/'} target={'_blank'}>
                            <img src={InstagramBlack} alt={'instagram'}/>
                        </a>
                    </div>
                </div>
                <div>
                    <img onClick={() => {setOpenMenu(!openMenu); setShowCategories(false)}} src={Hamburger} alt={'hamburger'}/>
                    {openMenu ? 
                    <div className={'toggleMenuContainer'}>
                        <div>
                            <span onClick={() => setShowCategories(!showCategories)}>
                                دسته بندی
                            </span>
                            {showCategories && allCategories.map((item: any, index: number) => (
                                <button onClick={() => {navigate(categoryUrl(item))}}>
                                    {item.icon ? <img style={{width: 24, height: 24}} src={`https://toomar.co${item.icon}`}/> : <img src={index == 0 ? WeddingCardBlack : index == 1 ? WeddingDigitalCard : index == 2 ? WeddingGift : index == 3 ? Office : index == 4 ? MemorialBoard : SpecialGift}/>}
                                    <span>
                                        {item.name}
                                    </span>
                                </button>
                            ))}
                            
                        </div>
                        <Link to={'/gallery/'}>
                            <span>
                                گالری محصولات ترحیم
                            </span>
                        </Link>
                        <Link to={'https://toomar.co/'}>
                            <span>
                                اقلام عروسی
                            </span>
                        </Link>
                        <Link to={'/magazine/'}>
                            <span>
                                مجله ی طومار
                            </span>
                        </Link>
                        <Link to={'/about-us/'}>
                            <span>
                                درباره ما
                            </span>
                        </Link>
                        <Link to={'/contact-us/'}>
                            <span>
                                تماس با ما
                            </span>
                        </Link>
                        <Link to='/followup-order/'>
                            <span>
                                پیگیری سفارش
                            </span>
                        </Link>
                    </div>
                    : null}
                    <div>
                        {
                            openSearch ? 
                            <div className='searchContainer'>
                                <input placeholder='کد یا دسته‌بندی مورد نظر را وارد کنید' value={searchText} onChange={(e) => handleGetResults(e)}/>
                                <img onClick={() => {setSearchResults([]); setSearchText(''); setOpenSearch(false)}} src={Close}/>
                                {(searchedResults.length > 0 || searchedCategories.length > 0) && searchText != '' && !inApi ?
                                <div className='byCodeContainer'>
                                    {searchedResults.length > 0 &&
                                        <p>براساس کد</p>
                                    }
                                    <div>
                                        {searchedResults.length > 0 && searchedResults.map(item => (
                                            <ToomarCard value={item}/>
                                        ))}
                                    </div>
                                    {searchedCategories.length > 0 &&
                                        <p>براساس دسته بندی</p>
                                    }
                                    <div>
                                        {
                                            searchedCategories.length > 0 &&
                                                searchedCategories.map((item: any) => (
                                                    <div>
                                                        <p onClick={() => {navigate(categoryUrl(item))}}>
                                                            {item.name}
                                                        </p>
                                                    </div>
                                                ))
                                        }
                                    </div>
                                </div>
                                : null }
                            </div>
                            : 
                            <img onClick={() => setOpenSearch(true)} src={Search} alt={'search'}/>
                        }
                        <Link to={'/favorite/'}>
                            <div>
                                {props.favoriteProducts?.length > 0 && 
                                    <p>
                                    {props.favoriteProducts?.length}  
                                    </p>
                                }
                                <img src={require("../../assets/icons/non_favorite.png")} alt={'bag'}/>
                            </div>
                        </Link>
                        {props.buyingProducts?.length > 0 ? 
                        <Link to={'/cart/'}>
                            <div>
                                <p>
                                  {props.buyingProducts?.length}  
                                </p>
                                <img src={Bag} alt={'bag'}/>
                            </div>
                        </Link>
                        : 
                        <div onClick={() => setCartModal(true)}>
                            <img src={Bag} alt={'bag'}/>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    buyingProducts: state.counter.buyingProducts,
    favoriteProducts: state.counter.favoriteProducts,
})

export default connect(mapStateToProps, { getSearchResults, getCategoriesTree })(Menu);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           