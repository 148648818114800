import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllProducts, getBlogs, getCategories, getSliders, getBanner } from '../../redux/actions';
import MainContainer from '../containers/mainContainer';
import Loading from '../../assets/icons/loadingHome.gif';
import Features from '../../assets/icons/featuresInToomar.svg';
import FeaturesMobile from '../../assets/icons/featuresInToomarMobile.svg';
import {Helmet} from "react-helmet";
import './home.scss';
import ToomarCardsSwiper from './toomarCardsSwiper';
import ObituryOfToomar from './obituryOfToomar';
import MostSold from './mostSold';
import ToomarGallery from './toomarGallery';
import LinkToWedding from './linkToWeddingCards';
import { Link } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';


function Home({ ...props }) {
    const theme = useTheme();
    const [obituryOfToomar, setObituryOfToomar] = useState([]);
    const [mostSellingOfToomar, setMostSellingOfToomar] = useState([]);
    const [mainSlider, setMainSlider] = useState([]);
    const [galleryCategories, setGalleryCategories] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [visible, setVisible] = useState(false);
    const [banner, setBanner] = useState<any>("");
    const isMobile = useMediaQuery(theme.breakpoints.down(800));

    useEffect(() => {
        const fetchData = async () => {
            await props.getBanner(1, (data: any) => setBanner(data.data.result));
            await props.getAllProducts({category: 999, ordering: '-created-at'}, (data: any) => setObituryOfToomar(data.data.results));
            await props.getAllProducts({category: 999, ordering: '-score'}, (data: any) => setMostSellingOfToomar(data.data.results));
            await props.getSliders("domain=1", undefined, (data: any) => setMainSlider(data.data.results));
            await props.getCategories("domain=1", 'parent=999', (data: any) => setGalleryCategories(data.data.results));
            await props.getBlogs(undefined, (data: any) => setBlogs(data.data.results));
            setVisible(true)
        }
        fetchData();

    }, [])
    if(visible) {
        return (
            <MainContainer>
                <Helmet>
                    <title>طومار|بزرگترین‌فروشگاه‌اینترنتی‌کارت‌ترحیم‌‌،مکه‌ای‌واقلام‌ترحیم</title>
                    <meta name="title" content="طومار|بزرگترین‌فروشگاه‌اینترنتی‌کارت‌ترحیم‌‌،مکه‌ای‌واقلام‌ترحیم" />
                    <meta
                        name="description"
                        content="خرید‌ انواع کارت ترحیم چاپی و دیجیتال،کارت مکه‌ای و کربلایی‌، اقلام‌ترحیم،ارسال به سراسر‌ایران|طومار"
                    />
                    <meta property="og:title" content="کارت ترحیم طومار" />
                    <meta property="og:description" content="خرید‌ انواع کارت ترحیم چاپی و دیجیتال،کارت مکه‌ای و کربلایی‌، اقلام‌ترحیم،ارسال به سراسر‌ایران|طومار" />
                    <meta property="og:url" content="https://toomar.co" />
                </Helmet>
                <div className={visible ? 'visible' : 'visible hide'}>
                    <ToomarCardsSwiper data={mainSlider}/>
                    {obituryOfToomar.length != 0 && <ObituryOfToomar data={obituryOfToomar}/>}
                    <Link to={"/gallery"}><img src={isMobile ? FeaturesMobile : Features} style={{width: "100%"}} alt={"toomarFeatures"}/></Link>
                    {mostSellingOfToomar.length != 0 && <MostSold data={mostSellingOfToomar}/>}
                    <ToomarGallery galleryCategories={galleryCategories}/>
                    {banner.image ?
                        <a className={"custom-banner"} href={banner.url} target={"_blank"}>
                            <img src={banner.image} alt={"گالری کارت ترحیم"} />
                        </a> 
                    : <a></a>}
                </div>
            </MainContainer>
        )
    }
    else {
        return (
            <div className={'loadingImg'}>
                <img src={Loading}/>
            </div>
        )
    }
    
}

export default connect(null, { getAllProducts, getSliders, getCategories, getBlogs, getBanner })(Home);