import React, { useEffect, useState } from 'react';
import './utils.scss';
import { getCategories, getSelectedCategories } from '../../redux/actions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { categoryUrl } from '../../utils/urlFormatter';


function AllFeatures({...props}) {
    const [galleryCategories, setGalleryCategories] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
        props.getSelectedCategories((data: any) => setGalleryCategories(data.data.results));
    }, [])

    return (
        <div className='allFeaturesContainer'>
            {galleryCategories.map((item: any, index: number) =>
                index <= 3 ?
                <button key={item.id} onClick={() => navigate(categoryUrl(item))}>
                    {item.icon && <img src={item.icon}/>}
                    <p>
                        {item.name}
                    </p>
                </button>
                : null
            )}
        </div>
    )
}

export default connect(null, { getCategories, getSelectedCategories })(AllFeatures);