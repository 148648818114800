import React from 'react';
import OrangeTick from '../../assets/icons/orangeTick.svg';
import "./utils.scss";

function RadioButtonWithOrangeTick({title, checked, onChange}: any) {
    return (
        <div className={'radioButtonContainer'} onClick={onChange}>
            <button>
                {checked ? <img src={OrangeTick} alt={'checking'}/> : null}
            </button>
            <p>
                {title}
            </p>
        </div>
    )
}

export default RadioButtonWithOrangeTick;