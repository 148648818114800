import React from 'react';
import './card.scss';
import ArrowLeft from '../../assets/icons/arrowLeftLong.svg';
import { Link } from 'react-router-dom';

function ToomarBlogCard({data, ...props}: any) {
    return (
        <Link to={`/magazine/${data.id}`}>
            <div className={'toomarBlogCardContainer'}>
                <img src={data.image} alt={'کارت ترحیم'}/>
                <p>
                    {data.title}
                </p>
                <div className={'more'}>
                    <span>
                        ادامه مطلب
                    </span>
                    <img src={ArrowLeft} alt={'more'}/>
                </div>
                <div className={'date'}>
                    بهمن ۱۴۰۰
                </div>
            </div>
        </Link>
    )
}

export default ToomarBlogCard;