import React from 'react';
import Footer from '../footer/footer';
import Menu from '../menu/menu';

function MainContainer({children, ...props}: any) {
    return (
        <div>
            <Menu/>
            {children}
            <Footer/>
        </div>
    )
}


export default MainContainer;