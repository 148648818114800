import React, { useState } from 'react';
import View from '../../assets/icons/eye.svg';
import Edit from '../../assets/icons/edit.svg';
import EditWhite from '../../assets/icons/editWhite.svg';
import { toast } from 'react-toastify';
import { Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Close from '../../assets/icons/circleClose.svg';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCardTitles } from '../../redux/actions';
import RadioButtonWithOrangeTick from '../utils/RadioButtonWithOrangeTick';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '1100px',
        maxWidth: '1200px!important',
        // height: 'var(--dialog-height)',
        borderRadius: '24px',
        "@media (max-width:800px)": { 
            width: '100%',
            margin: '0px !important'
        },
    }
}));


function AcceptData({onFinished, setStep, ...props}: any) {

    const [acceptLaw, setAcceptLaw] = useState(false);
    const [openDetailsModal, setOpenDetailsModal] = useState<any>(false);
    const classes = useStyles();
    const params: any = useParams();

    const handleGoEdit = () => {
        if(openDetailsModal == 'textDetails') {
            setStep(1); 
            props.setJustThisStep(true)
        }
        else if(openDetailsModal == 'titleDetails') {
            setStep(2); 
            props.setJustThisStep(true);
        }
        else {
            setStep(3); 
            props.setJustThisStep(true);
        }
    }
    return (
        <div className={'acceptDataContainer'}>
            <Dialog
            open={openDetailsModal == 'textDetails' || openDetailsModal == 'titleDetails' || openDetailsModal == 'dataDetails'}
            hideBackdrop={false}
            onClose={() => setOpenDetailsModal('')}
            PaperProps={{
                className: classes.root
            }}
            >
                <div className={'acceptDetailsReview'}>
                    <div className={'header'}>
                        <p>
                            {openDetailsModal == 'textDetails' ? 'پیش نمایش متن' : openDetailsModal == 'titleDetails' ? `پیش نمایش ${props.buyingProducts[params.id - 1].data.is_tarhim || props.buyingProducts[params.id - 1].data.is_tarhim ? "تیتر و نوع چاپ" : "نوع چاپ"}` : 'پیش نمایش اطلاعات'}
                        </p>
                        <div onClick={() => setOpenDetailsModal('')}>
                            <p>
                            بستن                              
                            </p>
                            <img src={Close}/>
                        </div>
                    </div>
                    {openDetailsModal == 'textDetails' ?
                        props.chooseText !== null ?
                        <img className={'cardTextImage'} src={props.chooseText.image}/> 
                        : 
                        <p>
                            {props.customText}
                        </p>
                    :
                    openDetailsModal == 'titleDetails' ?
                        <div className={'titleDetailsContainer'}>
                            <div>
                                <p>
                                    نوع چاپ
                                </p>
                                <button>
                                    {props.typeOfPrint == 1 ? 'رنگی' : 'برجسته'}
                                </button>
                            </div>
                            <div>
                            {props.buyingProducts[params.id - 1].data.is_card_mourning && props.buyingProducts[params.id - 1].data.is_tarhim ? null : 
                            <>
                                <p>
                                    رنگ
                                </p>
                                <button 
                                className={props.typeOfPlan == 0 ? 'black':
                                props.typeOfPlan == 1 ? 'brown' :
                                props.typeOfPlan == 2 ? 'red' :
                                props.typeOfPlan == 3 ? 'yellow' :
                                props.typeOfPlan == 4 ? 'gray' :
                                props.typeOfPlan == 5 ? 'gold' :
                                props.typeOfPlan == 6 ? 'silver' : 'silver'}>
                                    {props.typeOfPlan == 0 ? 'مشکی':
                                    props.typeOfPlan == 1 ? 'قهوه‌ای' :
                                    props.typeOfPlan == 2 ? 'قرمز' :
                                    props.typeOfPlan == 3 ? 'زرد' :
                                    props.typeOfPlan == 4 ? 'سفید مخصوص کارت های پلکسی' :
                                    props.typeOfPlan == 5 ? 'طلاکوب' :
                                    props.typeOfPlan == 6 ? 'نقره کوب' : 'نقره کوب'}
                                </button>
                            </>
                            }
                            </div>
                            <div style={props.buyingProducts[params.id - 1]?.data.has_latin !== true ? {margin: "0px"} : {}}>
                                {props.buyingProducts[params.id - 1]?.data.has_latin == true &&
                                    <>
                                        <p>
                                        نوع چاپ لاتین
                                        </p>
                                        <button 
                                        className={props.typeOfPrintForLatin == 0 ? 'black':
                                        props.typeOfPrintForLatin == 5 ? 'gold' :
                                        props.typeOfPrintForLatin == 6 ? 'silver' : 'silver'}>
                                            {props.typeOfPrintForLatin == 0 ? 'مشکی':
                                            props.typeOfPrintForLatin == 5 ? 'طلایی' :
                                            props.typeOfPrintForLatin == 6 ? 'نقره‌ای' : 'نقره‌ای'}
                                        </button>
                                    </>
                                }
                            </div>
                            {props.typeOfTitle.image ?
                            <div>
                                <p>
                                    نوع تیتر
                                </p>
                                <img src={props.typeOfTitle.image}/>
                            </div>
                            : null}
                        </div>
                    :
                        <div className={'dataDetailsContainer'}>
                            <p>
                                اطلاعات
                            </p>
                            <div>
                                <p>
                                    نام
                                </p>
                                <p>
                                    {props.personName}
                                </p>
                                <p>
                                    نام خانوادگی
                                </p>
                                <p>
                                    {props.personFamilyName}
                                </p>
                                {props.buyingProducts[params.id - 1].data.is_tarhim || props.buyingProducts[params.id - 1].data.is_tarhim === undefined ? 
                                <>
                                    <p>
                                        لقب
                                    </p>
                                    <p>
                                        {props.nickName}
                                    </p>
                                </>
                                : null}
                            </div>
                            {/* <p>
                                اطلاعات آقا داماد
                            </p>
                            <div>
                                <p>
                                    نام
                                </p>
                                <p>
                                    {props.manName}
                                </p>
                                <p>
                                    نام خانوادگی
                                </p>
                                <p>
                                    {props.manFamilyName}
                                </p>
                                <p>
                                    نام لاتین انگلیسی
                                </p>
                                <p>
                                    {props.manLatinName}
                                </p>
                            </div> */}
                            <div>
                                <p>
                                    مراسم از ساعت:
                                </p>
                                <p>
                                    {`${props.startHour}:${props.startMin}`}
                                </p>
                                <p>
                                    تا ساعت:
                                </p>
                                <p>
                                    {`${props.endHour}:${props.endMin}`}
                                </p>
                                <p>
                                    تاریخ مراسم:
                                </p>
                                <p>
                                    {`${props.year}/${props.month}/${props.day}`}
                                </p>
                            </div>
                            {/* <div>
                                <p>
                                    الویت نام بر روی کارت
                                </p>
                                <p>
                                    {props.whoIsFirst == 1 ? 'اول عروس خانم بعد آقا داماد' : 'اول آقا داماد بعد عروس خانم'}
                                </p>
                            </div> */}
                            <div>
                                <p>
                                    آدرس 
                                </p>
                                <p>
                                    {props.address}
                                </p>
                            </div>
                        </div>
                    }
                    <button onClick={handleGoEdit}>
                        <img src={EditWhite}/>
                        <p>
                            ویرایش
                        </p>
                    </button>
                </div>

            </Dialog>
            <p>
                لطفا درستی اطلاعات را بررسی و تایید نمایید.
            </p>
            <div>
                <div>
                    <p>
                        مراحل ثبت اطلاعات
                    </p>
                    <p>
                        نمایش
                    </p>
                    <p>
                        ویرایش
                    </p>
                </div>
                {props.product.data.is_card_mourning === true && props.product.data.is_tarhim || props.product.data.is_tarhim === undefined ? null :
                <div>
                    <p>
                        انتخاب متن 
                    </p>
                    <div>
                        <img onClick={() => setOpenDetailsModal('textDetails')} src={View} alt={'view'}/>
                    </div>
                    <div onClick={() => {setStep(1); props.setJustThisStep(true)}}>
                        <img src={Edit} alt={'edit'}/>
                    </div>
                </div>
                }
                {props.product.data.is_tarhim === undefined || (props.product.data.is_tarhim === false && props.product.data.is_digital_mourning) ? null :
                <div>
                    <p>
                        {props.buyingProducts[params.id - 1].data.is_tarhim || props.buyingProducts[params.id - 1].data.is_tarhim ? "تیتر و نوع چاپ" : "نوع چاپ"} 
                    </p>
                    <div onClick={() => setOpenDetailsModal('titleDetails')}>
                        <img src={View} alt={'view'}/>
                    </div>
                    <div onClick={() => {setStep(2); props.setJustThisStep(true)}}>
                        <img src={Edit} alt={'edit'}/>
                    </div>
                </div>
                }
                <div>
                    <p>
                        اطلاعات 
                    </p>
                    <div onClick={() => setOpenDetailsModal('dataDetails')}>
                        <img src={View} alt={'view'}/>
                    </div>
                    <div onClick={() => {setStep(3); props.setJustThisStep(true)}}>
                        <img src={Edit} alt={'edit'}/>
                    </div>
                </div>
            </div>
            <p>
                قوانین و مقررات 
            </p>
            <p>
                <span>۱ـ </span>مشتری گرامی مونتاژ (سر هم کردن) کلیه کارت های چاپی طبق روال بازار به عهده شما مشتریان عزیز می‌باشد.
            </p>
            <p>
                <span>۲ـ </span>شرکت طومار تضمین میکند دقیقا همان تصویری که در عکس نمونه مشاهده کرده اید، مطابق با اطلاعات ثبت شده شما تحویل دهد.
            </p>
            <p>
                <span>۳ـ </span> قبل از چاپ یا خروجی گرفتن محصول شما مشتری عزیز، تیم پشتیبانی طومار از طریق تلگرام،واتساپ یا.... شماره‌ی ثبت شده با شما در ارتباط خواهد بود و نمونه متن طراحی شده را برای شما ارسال می‌کند و تا ۳ مرتبه میتوانید بدون هزینه تغییراتی که مدنظر دارید را با طراحان مجموعه در میان گذاشته تا اعمال شوند، سپس بعد از تاییدیه شما وارد مرحله چاپ یا خروجی کارت می‌شود.
            </p>
            <p>
                <span>۴ـ </span> شرکت طومار تا ۲۴ ساعت پس از تحویل محصولات شما مشتریان عزیز مسئولیت هر گونه مشکل در تعداد و خرابی های احتمالی را به عهده میگیرد.
            </p>
            <p>
                <span>۵ـ </span>هزینه ارسال برای عزیزانی که داخل تهران هستند و بالای یک میلیون ۵۰۰ هزار تومان خرید از سایت انجام داده اند ، بصورت رایگان میباشد(ارسال با تیپاکس)و عزیزانی که خارج از تهران تشریف دارند ، درصورت خرید بالای ۳ میلیون تومان از سایت ، هزینه ارسالشان رایگان میباشد(ارسال با تیپاکس)
            </p>
            <p>
                <span>۶ـ </span>فرایند آماده سازی تا تحویل تمامی محصولات؛ برای سفارشات داخل تهران ۳ الی ۵ روز کاری و خارج تهران ۴ الی ۷ روز کاری از لحظه ثبت سفارش میباشد بجز کارت های عروسی دیجیتال که ظرف ۴۸ ساعت کاری و طراحی اعلامیه که ۱۲ ساعته آماده میشود .
            </p>
            <p>
                کلام آخر:
            </p>
            <p>
            سپاس فراوان از شما عزیزان که به ما اعتماد و سایت طومار را برای خرید انتخاب کردید. تیم ما همه‌ی تلاش خود را میکند تا کار شما مشتری عزیز را به نحو احسن انجام دهد. امیدواریم ثمره‌ی کار ما رضایت قلبی شما عزیزان باشد.                    
            </p>
            <p style={{marginBottom: "4px"}}>
                ارادتمند شما:
            </p>
            <p style={{margin: "0px"}}>
                عرفان مهری
            </p>
            <p>
                مدیریت مجموعه طومار
            </p>
            <div>
                <RadioButtonWithOrangeTick
                checked={acceptLaw}
                onChange={() => setAcceptLaw(!acceptLaw)}
                title={'قوانین و مقررات سایت را مطالعه کرده ام و قبول می کنم.'}
                />
            </div>
            <div>
                <button onClick={() => setStep(3)}>
                    بازگشت
                </button>
                <button onClick={() => acceptLaw == true ? onFinished() : toast.error('پذیرفتن قوانین الزامی است')}>
                        تایید و ادامه
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    buyingProducts: state.counter.buyingProducts,
})

export default connect(mapStateToProps, { getCardTitles })(AcceptData);
