import React from 'react';
import { Link } from 'react-router-dom';
// import Telegram from '../../assets/icons/telegram.svg';
// import Whatsapp from '../../assets/icons/whatsapp.svg';
import './footer.scss';
function Footer() {
    return (
        <div className={'footerContainer'}>
            <div>
                <div>
                    <p>
                        دسترسی سریع
                    </p>
                    <Link to={'/contact-us'}>
                        <p>
                            تماس با طومار
                        </p>
                    </Link>
                    <Link to={'/offers-critics'}>
                    <p>
                        پیشنهادات و انتقادات
                    </p>
                    </Link>
                    <Link to={'/laws'}>
                        <p>
                            قوانین و مقرارات
                        </p>
                    </Link>
                </div>
                <div>
                    <Link to={'/repetitive-questions'}>
                        <p>
                            سوالات متداول
                        </p>
                    </Link>
                    <Link to={'/order-guide'}>
                        <p>
                            راهنمای ثبت سفارش
                        </p>    
                    </Link>
                    <Link to={'/followup-order'}>
                        <p>
                            پیگیری سفارشات
                        </p>    
                    </Link>
                </div>
                <div>
                    <p>
                        فروشگاه اینترنتی طومار
                    </p>
                    <p>
                    فروشگاه اینترنتی طومار ، به عنوان بزرگترین فروشگاه اینترنتی کارت و اقلام عروسی/ترحیم در خدمت شما عزیزان میباشد که تمامی اقلام مربوطه را در بستر فروش آنلاین به شما عزیزان ارائه میدهد ، این فروشگاه در سال 1397 فعالیت خود را آغاز کرد.
                    </p>
                    <div>
                        <a target="_blank" href='https://trustseal.enamad.ir/?id=133164&Code=dS6TNxr7gyhbKf1zf5fABYfc52EBccpX'>
                            <img src={require("../../assets/icons/ENAMAD.png")} alt="" style={{cursor: 'pointer'}} id="I1qlXqUXg8eHAos8EAWS"/>
                            {/* <img src="https://trustseal.enamad.ir/logo.aspx?id=133164&Code=dS6TNxr7gyhbKf1zf5fABYfc52EBccpX" alt="" style={{cursor: 'pointer'}} data-Code="dS6TNxr7gyhbKf1zf5fABYfc52EBccpX"/> */}
                        </a>
                        <a href="javascript:showZPTrust()" title="دروازه پرداخت معتبر">
                            <img src="https://cdn.zarinpal.com/badges/trustLogo/1.png" alt="دروازه پرداخت معتبر"/>
                        </a>
                    </div>
                </div>
            </div>
            <p>
            کلیه حقوق مادی و معنوی این سایت برای فروشگاه اینترنتی کارت و اقلام عروسی/ترحیم طومار محفوظ است. ۲۰۲۳ ©
            </p>
        </div>
    )
}

export default Footer;