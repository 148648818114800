import React from 'react';
import { toast } from 'react-toastify';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import ToomarInput from '../utils/toomarInput';
import { hours, minutes } from '../utils/timeObjects';
import RadioButton from '../utils/radioButton';
import ToomarTextarea from '../utils/ToomarTextarea';

function AddData({ 
    personName,
    setPersonName,
    nickName,
    setNickName,
    personFamilyName,
    setPersonFamilyName,
    typeOfBegayi,
    setTypeOfBegayi,
    startHour,
    setStartHour,
    startMin,
    setStartMin,
    endHour,
    setEndHour,
    endMin,
    setEndMin,
    address,
    setAddress,
    forWhat,
    setForWhat,
    description,
    setDescription,
    day,
    setDay,
    month,
    setMonth,
    year,
    setYear,
    setStep,
    onFinished, 
    ...props }: any) {

    const search = useLocation().search;
    const queryStep: any = new URLSearchParams(search).get('step');
    const params: any = useParams();

    const goBack = () => {
        if(queryStep == 3) {
                window.location.replace('/cart/')            
        }
        else if(props.buyingProducts[params.id - 1].data.is_digital_mourning) {
            if(props.justThisStep == true) {
                setStep(4);
                props.setJustThisStep(false)
            }
            else {
                setStep(1)
            }
        }
        else {
            if(props.justThisStep == true) {
                setStep(4);
                props.setJustThisStep(false)
            }
            else {
                setStep(2)
            }
        }
        
    }

    const handleGoNext = () => {
        // if(womanName !== '' && womanFamilyName !== '' && womanLatinName !== '' && manName !== '' && manFamilyName !== '' && manLatinName !== '' && address !== '' && day !== '' && month !== '' && year !== '') {
        //     setStep(4);
        //     props.setJustThisStep(false)
        // }
        if(!(props.buyingProducts[params.id - 1].data.is_tarhim || props.buyingProducts[params.id - 1].data.is_tarhim === undefined) && personName !== '' && personFamilyName !== '' && forWhat !== '' && address !== '' && day !== '' && month !== '' && year !== '') {
            setStep(4);
            props.setJustThisStep(false)
        }
        else if((props.buyingProducts[params.id - 1].data.is_tarhim || props.buyingProducts[params.id - 1].data.is_tarhim === undefined) && personName !== '' && personFamilyName !== '' && forWhat !== '' && address !== '' && day !== '' && month !== '' && year !== '' && nickName !== "") {
            setStep(4);
            props.setJustThisStep(false)
        }
        else if(queryStep == 3 && personName !== '' && personFamilyName !== '') {
            // setStep(4);
            onFinished();
            window.location.replace('/cart/');
        }
        else {
            toast.error('اطلاعات را کامل کنید')
        }
    }
    return (
        <>
        {props.buyingProducts[params.id - 1].data.is_tarhim || 
        (props.buyingProducts[params.id - 1].data.is_tarhim === undefined && props.buyingProducts[params.id - 1].data.is_digital_mourning) ?
        <>
            <p className='typeOfBegayiTitle tw-mx-4'>
                لطفا نوع مراسم را انتخاب کنید
            </p>
            <div className={'typeOfBegayi'}>
                <button className={typeOfBegayi === 0 ? "selected" : "tw-bg-white tw-border tw-border-black tw-rounded-lg tw-h-8"} onClick={() => setTypeOfBegayi(0)}>
                    ختم و خاکسپاری
                </button>
                <button className={typeOfBegayi === 1 ? "selected" : "tw-bg-white tw-border tw-border-black tw-rounded-lg tw-h-8"} onClick={() => setTypeOfBegayi(1)}>
                    مراسم هفتم
                </button>
                <button className={typeOfBegayi === 2 ? "selected" : "tw-bg-white tw-border tw-border-black tw-rounded-lg tw-h-8"} onClick={() => setTypeOfBegayi(2)}>
                    مراسم چهل
                </button>
                <button className={typeOfBegayi === 3 ? "selected" : "tw-bg-white tw-border tw-border-black tw-rounded-lg tw-h-8"} onClick={() => setTypeOfBegayi(3)}>
                    اولین سالگرد
                </button>
                <button className={typeOfBegayi === 4 ? "selected" : "tw-bg-white tw-border tw-border-black tw-rounded-lg tw-h-8"} onClick={() => setTypeOfBegayi(4)}>
                    سالگرد
                </button>
            </div>
        </>
        : null}
        <div className={'addDataContainer'}>
            <p>
                {`اطلاعات ${props.buyingProducts[params.id - 1].data.is_tarhim || props.buyingProducts[params.id - 1].data.is_tarhim === undefined ? "متوفی" : "حجاج/کربلایی"}`}
            </p>
            <div>
                <ToomarInput
                title={'نام'}
                disabled={false}
                inputValue={personName}
                onChange={(e: any) => setPersonName(e.target.value)}
                />
                <ToomarInput
                title={'نام خانوادگی'}
                disabled={false}
                inputValue={personFamilyName}
                onChange={(e: any) => setPersonFamilyName(e.target.value)}
                />
                {props.buyingProducts[params.id - 1].data.is_tarhim || props.buyingProducts[params.id - 1].data.is_tarhim === undefined ?
                    <ToomarInput
                    title={'لقب'}
                    disabled={false}
                    inputValue={nickName}
                    onChange={(e: any) => setNickName(e.target.value)}
                    />
                : null}
                {/* <ToomarInput
                title={'نام لاتین انگلیسی'}
                disabled={false}
                inputValue={womanLatinName}
                onChange={(e: any) => setWomanLatinName(e.target.value)}
                /> */}
            </div>
            {/* <p>
                اطلاعات آقا داماد
            </p>
            <div>
                <ToomarInput
                title={'نام'}
                disabled={false}
                inputValue={manName}
                onChange={(e: any) => setManName(e.target.value)}
                />
                <ToomarInput
                title={'نام خانوادگی'}
                disabled={false}
                inputValue={manFamilyName}
                onChange={(e: any) => setManFamilyName(e.target.value)}
                />
                <ToomarInput
                title={'نام لاتین انگلیسی'}
                disabled={false}
                inputValue={manLatinName}
                onChange={(e: any) => setManLatinName(e.target.value)}
                />
            </div> */}
            
            {/* <p>
                لطفا الویت قرار گرفتن نام ها روی کارت ها را مشخص نمایید.
            </p>
            <div>
                <select value={whoIsFirst} onChange={(e) => setWhoIsFirst(e.target.value)}>
                    <option value={1}>اول عروس خانم بعد آقا داماد</option>
                    <option value={2}>اول آقا داماد بعد عروس خانم</option>
                </select>
            </div> */}
            <p>
                لطفا اطلاعات مراسم را با دقت وارد نمایید.
            </p>
            <div>
                <p>
                    تاریخ مراسم:
                </p>
                <div>
                    <ToomarInput
                    placeholder={'روز'}
                    inputValue={day}
                    onChange={(e: any) => setDay(e.target.value)}
                    />
                    <ToomarInput
                    placeholder={'ماه'}
                    inputValue={month}
                    onChange={(e: any) => setMonth(e.target.value)}
                    />
                    <ToomarInput
                    placeholder={'سال'}
                    inputValue={year}
                    onChange={(e: any) => setYear(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <div>
                    <p>
                        مراسم از ساعت:
                    </p>
                    <div>
                        <div>
                            <select value={startMin} onChange={(e) => setStartMin(e.target.value)}>
                                {minutes.map((item, index) => (
                                    <option value={index}>{index}</option>
                                ))}
                            </select>
                            :
                            <select value={startHour} onChange={(e) => setStartHour(e.target.value)}>
                                {hours.map((item, index) => (
                                    <option value={index + 1}>{index + 1}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    <p>
                        تا ساعت:
                    </p>
                    <div>
                        <div>
                            <select disabled={false} value={endMin} onChange={(e) => setEndMin(e.target.value)}>
                                {minutes.map((item, index) => (
                                    <option value={index}>{index}</option>
                                ))}
                            </select>
                            :
                            <select disabled={false} value={endHour} onChange={(e) => setEndHour(e.target.value)}>
                                {hours.map((item, index) => (
                                    <option value={index + 1}>{index + 1}</option>
                                ))}
                            </select>
                        </div>
                        {/* <div>
                            <p>
                                تا پاسی از شب
                            </p>
                            <RadioButton checked={isToNight} onChange={() => setIsToNight(!isToNight)}/>
                        </div> */}
                    </div>
                </div>
            </div>
            <div>
                <ToomarInput
                title={'نشانی مراسم'}
                disabled={false}
                inputValue={address}
                onChange={(e: any) => setAddress(e.target.value)}
                />
                <ToomarInput
                title={'به صرف'}
                disabled={false}
                inputValue={forWhat}
                onChange={(e: any) => setForWhat(e.target.value)}
                />
            </div>
            <ToomarTextarea
            title={"توضیحات"}
            disabled={false}
            inputValue={description}
            onChange={(e: any) => setDescription(e.target.value)}
            />
            {/* {props.buyingProducts[params.id - 1].data.is_digital != true || props.  ?
            <>
                <p>
                    لطفا در صورت تمایل آهنگ پس زمینه دلخواه خود را وارد نمایید.
                </p>
                <div>
                    <ToomarInput
                    title={'نام آهنگ'}
                    disabled={false}
                    inputValue={songName}
                    onChange={(e) => setSongName(e.target.value)}
                    />
                    <ToomarInput
                    title={'نام خواننده'}
                    disabled={false}
                    inputValue={artistName}
                    onChange={(e) => setArtistName(e.target.value)}
                    />
                </div>
            </>
            : null} */}
            <div className='changeStepContainer'>
                <button onClick={goBack}>
                    بازگشت
                </button>
                <button onClick={handleGoNext}>
                    تایید و ادامه
                </button>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    buyingProducts: state.counter.buyingProducts,
})

export default connect(mapStateToProps, null)(AddData);
