import React, { useEffect, useState } from 'react';
import './magazine.scss';
import { useParams } from 'react-router-dom';
import { getOneBlog } from '../../redux/actions';
import { connect } from 'react-redux';
import MainContainer from '../containers/mainContainer';
// import {Helmet} from "react-helmet";

function MagazineDetails({...props}) {
    const [blogDetails, setBlogDetails] = useState<any>({});
    const params = useParams();

    useEffect(() => {
        props.getOneBlog(params.id, (data: any) => setBlogDetails(data.data))
    }, [])

    return (
        <MainContainer>
            {/*<Helmet>*/}
            {/*    <title>{blogDetails?.title}مجله طومار | </title>*/}
            {/*    <meta name="title" content="گالری محصولات طومار | مرجع اطلاع از قیمت و فروش کارت و اقلام عروسی" />*/}
            {/*    <meta name="description" content="بالای ۱۰۰۰ مدل کارت های عروسی چاپی در هفت دسته بندی مختلف با قیمت نهایی ، گالری کارت های عروسی دیجیتال ، گیفت عقد ، دفاتر بله برون و جهیزیه ، تابلو های یاد بود مراسم ، ست های بله برون و..." />*/}
            {/*    <meta property="og:title" content={blogDetails?.title} />*/}
            {/*    <meta property="og:url" content={""} />*/}
            {/*</Helmet>*/}
            <div className={'magazineDetailsContainer'}>
                <img src={blogDetails?.image} alt={'advertisement'}/>
                <div>
                    <p>
                        {blogDetails?.title}
                    </p>
                </div>
                <div dangerouslySetInnerHTML={{ __html: blogDetails?.content }} />
            </div>
        </MainContainer>
    )
}

export default connect(null, { getOneBlog })(MagazineDetails);