import { PURGE } from 'redux-persist';
import { GET_BOUGHT_PRODUCTS, GET_BUYING_PRODUCTS, GET_BUY_PRODUCT_DETAILS, GET_FAVORITE_PRODUCT, SET_PRODUCT_OBJECTS } from '../actions/types';

const INITIAL_STATE = {
    buyingProducts: [],
    boughtProducts: [],
    finishedProductDetails: [],
    favoriteProducts: [],
    productObj: []
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_BUYING_PRODUCTS:
            return {
                ...state,
                buyingProducts: action.payload
            }
        case GET_BOUGHT_PRODUCTS:
            return {
                ...state,
                boughtProducts: action.payload
            }
        case GET_BUY_PRODUCT_DETAILS:
            return {
                ...state,
                finishedProductDetails: action.payload
            }
        case GET_FAVORITE_PRODUCT:
            return {
                ...state,
                favoriteProducts: action.payload
            }
        case SET_PRODUCT_OBJECTS:
            return {
                ...state,
                productObj: action.payload
            }
        case PURGE:
                return {}
        default:
            return state
    }
}