import React, { useEffect, useState } from 'react';
import './repetitiveQuestions.scss';
import { getQuestions } from '../../redux/actions';
import { connect } from 'react-redux';
import MainContainer from '../containers/mainContainer';
import Title from '../utils/title';
import FollowUsInSocials from '../utils/followUpInSocials';
import CollapseBar from '../utils/collapseBar';

function RepetitiveQuestions({...props}) {
    const [questions, setQuestions] = useState<any>([])
    useEffect(() => {
        props.getQuestions((data: any) => setQuestions(data.data.result))
    }, [])
    return (
        <MainContainer>
            <Title
            goldText={''}
            blackText={'سوالات پرتکرار'}/>
            <div className={'repetitiveQuestionsContainerPage'}>
                {questions.map((question: any, index: number) => (
                    <CollapseBar question={question.question} answer={question.answer}/>
                ))}
                <FollowUsInSocials/>
            </div>
                
        </MainContainer>
    )
}

export default connect(null, { getQuestions })(RepetitiveQuestions);