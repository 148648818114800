import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/scrollbar"
import SwiperCore, {
  Navigation, Mousewheel, Scrollbar
} from 'swiper';
import { useMediaQuery, useTheme } from '@mui/material';
import ToomarCard from '../cards/toomarCard';
// install Swiper modules
SwiperCore.use([Navigation, Mousewheel, Scrollbar]);

function MostSold({data, ...props}: any) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));
    return (
        <div className={'toomarMostSoldContainer'}>
            <div>
                <p>
                    پرفروش‌ترین‌های
                </p>
                <p>
                    طومار
                </p>
            </div>
            <div>
                <Swiper cssMode={true} slidesPerView={isMobile ? 1 : 3} loop={true} navigation={true} scrollbar={{"hide": true}} mousewheel={true} className={"toomarMostSold"}>
                    {data.map((item: any) => 
                        <SwiperSlide>
                            <ToomarCard isMostSold={true} value={item} inMainPage={true}/>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>
    )
}

export default MostSold;