import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Home from './components/home/home';
import Gallery from './components/gallery/gallery';
import Product from './components/product/product';
import RegisterComplaints from './components/register-complaints/registerComplaints';
import AboutUs from './components/about-us/aboutUs';
import OffersAndCritics from './components/offers-and-critics/offersAndCritics';
import RepetitiveQuestions from './components/repetitive-questions/repetitiveQuestions';
import Laws from './components/laws/laws';
import ContactUs from './components/contact-us/contact-us';
import WhyToomarPage from './components/why-toomar/whyToomar';
import OrderGuide from './components/order-guide/orderGuide';
import Magazine from './components/magazine/magazine';
import MagazineDetails from './components/magazine/magazineDetails';
import Favorite from './components/favorite/favorite';
import FollowupOrder from './components/followup-order/followupOrder';
import FollowupLastOrder from './components/followup-last-order/followupLastOrder';
import Cart from './components/cart/cart';
import BuyProcess from './components/buy-process/buyProcess';

function App() {
  
  return (
    <div id={'top'} style={{position: 'relative'}}>
      <Routers/>
    </div>
  );
}

function Routers() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='gallery/:id/' element={<Gallery/>}/>
          <Route path='gallery' element={<Gallery/>}/>
          <Route path='gallery/:id/:name' element={<Gallery/>}/>
          <Route path='product/:id' element={<Product/>}/>
          <Route path='register-complaints' element={<RegisterComplaints/>}/>
          <Route path='about-us' element={<AboutUs/>}/>
          <Route path='offers-critics' element={<OffersAndCritics/>}/>
          <Route path='repetitive-questions' element={<RepetitiveQuestions/>}/>
          <Route path='laws' element={<Laws/>}/>
          <Route path='contact-us' element={<ContactUs/>}/>
          <Route path='why-toomar' element={<WhyToomarPage/>}/>
          <Route path='order-guide' element={<OrderGuide/>}/>
          <Route path='magazine' element={<Magazine/>}/>
          <Route path='magazine/:id' element={<MagazineDetails/>}/>
          <Route path='favorite' element={<Favorite/>}/>
          <Route path='followup-order' element={<FollowupOrder/>}/>
          <Route path='followup-last-order/:id' element={<FollowupLastOrder/>}/>
          <Route path='cart' element={<Cart/>}/>
          <Route path='buy-process/:id' element={<BuyProcess/>}/>
        </Routes>
      </BrowserRouter>
  )
}

export default App;
