import React, { useEffect, useState } from 'react';
import './magazine.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"
import SwiperCore, {
  Navigation,Pagination,Mousewheel
} from 'swiper';
import Swiper1 from '../../assets/icons/swiper1.svg';
import { getBlogs } from '../../redux/actions';
import { connect } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';
import {Helmet} from "react-helmet";
import MainContainer from '../containers/mainContainer';
import Title from '../utils/title';
import ToomarBlogCard from '../cards/toomarBlogCard';
import { Link } from 'react-router-dom';
// install Swiper modules
SwiperCore.use([Navigation,Pagination,Mousewheel]);

function Magazine({...props}) {

    const [blogs, setBlogs] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));

    useEffect(() => {
        props.getBlogs(undefined, (data: any) => setBlogs(data.data.results));
    }, [])

    return (
        <MainContainer>
            <Helmet>
                <title>مجله طومار | بزرگترین فروشگاه اینترنتی کارت و اقلام عروسی</title>
                <meta name="title" content="مجله طومار | بزرگترین فروشگاه اینترنتی کارت و اقلام عروسی" />
                <meta name="description" content="جدید ترین اخبار مربوط به عروسی و نکاتی بسیار مهم راجب انتخاب کارت و اقلام عروسی ، همه در مجله طومار" />
                <meta property="og:title" content="مجله" />
                <meta property="og:url" content="https://toomar.co/magazine" />
            </Helmet>
            {blogs.length != 0 ?
            <div className={'magazineContainer'}>
                <Title blackText={'مجله‌ی طومار'} goldText={''}/>
                <Swiper cssMode={true} slidesPerView={isMobile ? 1 : 3} navigation={true}  pagination={{dynamicBullets: true}} mousewheel={true} className={"toomarCardsSwiper"}>
                    {blogs.map(item => 
                    <SwiperSlide>
                        <ToomarBlogCard data={item}/>
                    </SwiperSlide>
                    )}
                </Swiper>
            </div>
            : null}
            {/* <TopFeatureOfToomar/> */}
            {blogs.length != 0 ?
            <Link to={`/gallery`}>
                <img src={Swiper1} alt={'advertisement'}/>
            </Link>
            : null}
            {blogs.length != 0 ?
            <div className={'magazineContainer'}>
                <Title blackText={'طومار'} goldText={'جدیدترین اخبار'}/>
                <Swiper cssMode={true} slidesPerView={isMobile ? 1 : 3} navigation={true}  pagination={{dynamicBullets: true}} mousewheel={true} className={"toomarCardsSwiper"}>
                    {blogs.map(item => 
                        <SwiperSlide>
                            <ToomarBlogCard data={item}/>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
            : null}
        </MainContainer>
    )
}

export default connect(null, { getBlogs })(Magazine);