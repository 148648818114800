import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import { getCardTitles } from '../../redux/actions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

function SelectTitle({
    typeOfTitle,
    setTypeOfTitle,
    typeOfPrintForLatin,
    setTypeOfPrintForLatin,
    typeOfPlan,
    setTypeOfPlan,
    typeOfPrint,
    setTypeOfPrint,
    setStep,
    ...props}: any) {
    const [allTitles, setAllTitles] = useState([]);
    const params: any = useParams();
    useEffect(() => {
        props.getCardTitles(undefined, (data: any) => {
            for(let i = 0; i < data.data.results.length; i++) {
                if(data.data.results[i].id == typeOfTitle.id) {
                    setTypeOfTitle(data.data.results[i]);
                }
            }
            setAllTitles(data.data.results);
        })
        if(props.buyingProducts[params.id - 1].data.is_koob == true) {
            setTypeOfPrint(2)
        }
    }, [])

    const goBack = () => {
        if(props.justThisStep == true) {
            setStep(4);
            props.setJustThisStep(false)
        }
        else {
            setStep(1)
        }
    }

    const handleGoNext = () => {
        if(
            (props.buyingProducts[params.id - 1].data.has_latin == true && typeOfPrintForLatin === '') ||
            typeOfPlan === '' ||
            typeOfPrint === '') {
            toast.error('اطلاعات را کامل کنید')
        }
        else {
            if(props.justThisStep == true) {
                props.setJustThisStep(false)
                setStep(4);
            }
            else {
                setStep(3)
            }
        }
    }

    return (
        <div className={'selectTitleContainer'}>
            {props.buyingProducts[params.id - 1].data.is_koob != true ?
            <>
            <p className={'titleName'}>
                لطفانوع چاپ متن را انتخاب کنید.
            </p>
            <div className={'typeOfPrint'}>
                <button style={typeOfPrint == 1 ? {backgroundColor: 'black', color: 'white'} : {}} onClick={() => setTypeOfPrint(1)}>
                    رنگی
                </button>
                <button style={typeOfPrint == 2 ? {backgroundColor: 'black', color: 'white'} : {}} onClick={() => {setTypeOfPrint(2); setTypeOfPlan(5)}}>
                    برجسته
                </button>
            </div>
            </>
            : null}
            <p className={'titleName'} style={{fontSize: "20px", textAlign: "center"}}>
                {typeOfPrint == 2 ? '(هر ۱۰۰ عدد ۵۹۵,۰۰۰ هزار تومان)' : ''}
            </p>
            {props.buyingProducts[params.id - 1].data.is_card_mourning && props.buyingProducts[params.id - 1].data.is_tarhim ? 
                <>
                    <p></p>
                    <div/> 
                </>
            :
            <>
                <p className={'titleName'}>
                    لطفانوع چاپ {typeOfPrint == 1 ? 'رنگی' : 'برجسته'} را انتخاب نمایید.
                </p>
                <div className={'typeOfPlan'}>
                    {typeOfPrint == 1 ?
                    <>
                        <button className={typeOfPlan == 0 ? 'black selected': 'black'} onClick={() => setTypeOfPlan(0)}>
                            مشکی
                        </button>
                        <button className={typeOfPlan == 1 ? 'brown selected': 'brown'} onClick={() => setTypeOfPlan(1)}>
                            قهوه‌ای
                        </button>
                    </> : 
                    <>
                        <button className={typeOfPlan == 5 ? 'gold selected': 'gold'} onClick={() => setTypeOfPlan(5)}>
                            طلاکوب
                        </button>
                        <button className={typeOfPlan == 6 ? 'silver selected': 'silver'} onClick={() => setTypeOfPlan(6)}>
                            نقره کوب
                        </button>
                    </>
                    }
                </div>
            </>
            }
            {/* {props.buyingProducts[params.id - 1].data.has_latin == true ?
                <>
                    <p className={'titleName'}>
                        لطفانوچاپ لاتین برای اسم عروس و داماد بر روی پاکت کارت را انتخاب نمایید.
                    </p>
                    <div className={'typeOfPrintLatin'}>
                        <button className={typeOfPrintForLatin == 0 ? 'selected': ''} onClick={() => setTypeOfPrintForLatin(0)}>
                            مشکی
                        </button>
                        <button className={typeOfPrintForLatin == 5 ? 'selected': ''} onClick={() => setTypeOfPrintForLatin(5)}>
                            طلایی
                        </button>
                        <button className={typeOfPrintForLatin == 6 ? 'selected': ''} onClick={() => setTypeOfPrintForLatin(6)}>
                            نقره ای
                        </button>
                    </div>
                </> : null} */}
            {allTitles.length > 0 && props.buyingProducts[params.id - 1].data.is_card_mourning == true && props.buyingProducts[params.id - 1].data.is_tarhim == true ?
            <>
                <p className={'titleName'}>
                    لطفا در صورت تمایل نوع تیتر را انتخاب نمایید.
                </p>
                <div className={'typeOfTitr'}>
                <Swiper
                cssMode={true}
                navigation={true}
                pagination={{dynamicBullets: true}}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="titleType"
                >
                    {allTitles.map((item: any) => (
                        <SwiperSlide>
                            <div className={'titleContainerBuyProcess'} onClick={() => setTypeOfTitle(item)}>
                                <img src={item.image} alt={'کارت ترحیم'}/>
                                <button className={item.id == typeOfTitle.id ? 'selected' : ''}>
                                    انتخاب
                                </button>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                </div>
            </>
            : null}
            <div className={'backOrNextContainer'}>
                {props.buyingProducts[params.id - 1].data.is_card_mourning === true && props.buyingProducts[params.id - 1].data.is_tarhim ? <div/> :
                    <button onClick={goBack}>
                        بازگشت
                    </button>
                }
                <button onClick={handleGoNext}>
                    تایید و ادامه
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    buyingProducts: state.counter.buyingProducts,
})

export default connect(mapStateToProps, { getCardTitles })(SelectTitle);
