import { Swiper, SwiperSlide } from "swiper/react";
import Swiper1 from '../../assets/icons/swiper1.svg';
import Swiper2 from '../../assets/icons/swiper2.svg';
import Swiper3 from '../../assets/icons/swiper3.svg';
import Swiper4 from '../../assets/icons/swiper4.svg';
import Swiper5 from '../../assets/icons/swiper5.svg';
import Swiper1Mobile from '../../assets/icons/swiper1Mobile.svg';
import Swiper2Mobile from '../../assets/icons/swiper2Mobile.svg';
import Swiper3Mobile from '../../assets/icons/swiper3Mobile.svg';
import Swiper4Mobile from '../../assets/icons/swiper4Mobile.svg';
import Swiper5Mobile from '../../assets/icons/swiper5Mobile.svg';
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"
import SwiperCore, {
  Navigation,Pagination,Mousewheel
} from 'swiper';
import { Link } from 'react-router-dom';
import { useMediaQuery, useTheme } from "@mui/material";

// install Swiper modules
SwiperCore.use([Navigation,Pagination,Mousewheel]);

function ToomarCardsSwiper({data, ...props}: any) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));
    return (
        <div className={'toomarCardsSwiperContainer'}>
           <Swiper pagination={{dynamicBullets: true}} cssMode={true} navigation={true} loop={true}  mousewheel={true} className={"toomarCardsSwiper"}>
                <SwiperSlide>
                    <Link to={`/gallery`}>
                        <div className={'toomarCardsSwiperItemContainer'}>
                            <img src={isMobile ? Swiper1Mobile : Swiper1} alt={'ترحیم'}/>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/gallery`}>
                        <div className={'toomarCardsSwiperItemContainer'}>
                            <img src={isMobile ? Swiper2Mobile : Swiper2} alt={'ترحیم'}/>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/gallery`}>
                        <div className={'toomarCardsSwiperItemContainer'}>
                            <img src={isMobile ? Swiper3Mobile : Swiper3} alt={'ترحیم'}/>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/gallery`}>
                        <div className={'toomarCardsSwiperItemContainer'}>
                            <img src={isMobile ? Swiper4Mobile : Swiper4} alt={'ترحیم'}/>
                        </div>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={`/gallery`}>
                        <div className={'toomarCardsSwiperItemContainer'}>
                            <img src={isMobile ? Swiper5Mobile : Swiper5} alt={'ترحیم'}/>
                        </div>
                    </Link>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default ToomarCardsSwiper;