import React, { useState } from 'react';
import { connect } from 'react-redux';
import { sentTicket } from '../../redux/actions';
import './offersAndCritics.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainContainer from '../containers/mainContainer';
import Title from '../utils/title';
import ToomarInput from '../utils/toomarInput';
import ToomarTextarea from '../utils/ToomarTextarea';
import FollowUsInSocials from '../utils/followUpInSocials';

function OffersAndCritics({...props}) {
    const [name, setName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');

    const handleSentTicket = () => {
        let data = {
            "phone_number": phoneNumber,
            "content": comment,
            "email": email,
            "name": name + " " + familyName,
            "subject": "انتقاد و پیشنهاد",
        }
        props.sentTicket(data, () => {
            toast("با موفقیت ثبت شد!");
            setName('');
            setFamilyName('');
            setPhoneNumber('');
            setEmail('');
            setComment('');
            },
            () => toast("مشکلی پیش آمده!"))    
    }

    return (
        <MainContainer>
            <ToastContainer />
            <Title
            goldText={''}
            blackText={'پیشنهادات و انتقادات'}/>
            <div className={'offersAndCriticsContainer'}>
                <p>
                    کاربر گرامی تیم طومار همه ی تلاش خودرا میکند تا بهترین خدمات را به شما ارائه دهد ، پیشنهادات و انتقادات سازنده شما کاربر های عزیز کمک شایانی به رشد مجموعه ما خواهد کرد ، از این که وقت میگذارید و نظرات خود را ارسال میکنید سپاسگزاریم .
                </p>
                <div>
                    <ToomarInput
                    title={'نام'}
                    inputValue={name}
                    onChange={(e: any) => setName(e.target.value)}
                    />
                    <ToomarInput
                    title={'نام خانوادگی'}
                    inputValue={familyName}
                    onChange={(e: any) => setFamilyName(e.target.value)}
                    />
                </div>
                <div>
                    <ToomarInput
                    title={'شماره موبایل'}
                    inputValue={phoneNumber}
                    maxLength={11}
                    errorMessage={phoneNumber.length != 11 ? "تلفن همراه باید ۱۱ رقمی باشد" : ""}
                    onChange={(e: any) => setPhoneNumber(e.target.value)}
                    />
                    <ToomarInput
                    title={'آدرس ایمیل'}
                    inputValue={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <ToomarTextarea
                    title={'نظر'}
                    inputValue={comment}
                    onChange={(e: any) => setComment(e.target.value)}
                    />
                </div>
                <div>
                    <button onClick={handleSentTicket}>
                        ارسال نظر
                    </button>
                </div>
                <FollowUsInSocials/>
            </div>
                
        </MainContainer>
    )
}

export default connect(null, { sentTicket })(OffersAndCritics);