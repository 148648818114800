import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"
import SwiperCore, {
  Navigation,Pagination,Mousewheel
} from 'swiper';
import { useMediaQuery, useTheme } from '@mui/material';
import ToomarCard from '../cards/toomarCard';
import Title from '../utils/title';

// install Swiper modules
SwiperCore.use([Navigation,Pagination,Mousewheel]);

function ObituryOfToomar({data, ...props}: any) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(800));
    return (
        <div className={'obituryOfToomarContainer'}>
            <Title blackText={'کارت‌های ترحیم'} goldText={''}/>
            <Swiper cssMode={true} loop={true} slidesPerView={isMobile ? 1 : 3} navigation={true} pagination={{dynamicBullets: true}} mousewheel={true} className={"toomarCardsSwiper toomarObiturySwiper"} spaceBetween={0}>
                {data.map((item: any) => 
                    <SwiperSlide>
                        <ToomarCard inMainPage={true} value={item}/>
                    </SwiperSlide>
                )}  
            </Swiper>
        </div>
    )
}

export default ObituryOfToomar;