import { PURGE } from 'redux-persist';

const INITIAL_STATE = {
    newsInfo: {},
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PURGE:
            return {};
        default:
            return state;
    }
}