import React, { useEffect, useState } from 'react';
import './favorite.scss';
import { connect } from 'react-redux';
import { setFavoriteProducts } from '../../redux/actions';
import MainContainer from '../containers/mainContainer';
import FavoriteCard from '../cards/favoriteCard';

function Favorite({...props}) {

    const deleteFavorite = (product: any) => {
        let index = props.favoriteProducts.findIndex((item: any) => item.id == product.id);
        let locObject = [...props.favoriteProducts];
        locObject.splice(index, 1);
        props.setFavoriteProducts([...locObject]);
    }

    return (
        <MainContainer>
            <div className='favoritesContainer'>
                <p>
                    علاقه‌مندی ها
                </p>
                <div>
                    <div>
                        {props.favoriteProducts.map((item: any, index: number) =>
                        index % 3 == 0 ? (
                            <FavoriteCard key={item.id} value={item} onDeleteFromFav={(data: any) => deleteFavorite(data)}/>
                        ) : null
                        )}
                    </div>
                    <div>
                        {props.favoriteProducts.map((item: any, index: number) =>
                        index % 3 == 1 ? (
                            <FavoriteCard key={item.id} value={item} onDeleteFromFav={(data: any) => deleteFavorite(data)}/>
                        ) : null
                        )}
                    </div>
                    <div>
                        {props.favoriteProducts.map((item: any, index: number) =>
                        index % 3 == 2 ? (
                            <FavoriteCard key={item.id} value={item} onDeleteFromFav={(data: any) => deleteFavorite(data)}/>
                        ) : null
                        )}
                    </div>
                </div>
            </div>
        </MainContainer>
    )
}

const mapStateToProps = (state: any) => ({
    favoriteProducts: state.counter.favoriteProducts,
})

export default connect(mapStateToProps, { setFavoriteProducts })(Favorite);