export const flatten = (
  children?: any,
  extractChildren?: any,
  level?: any,
  parent?: any
) =>
  Array.prototype.concat.apply(
    children.map((x: any) => ({
      ...x,
      level: level || 1,
      parent: parent || null,
    })),
    children.map((x: any) =>
      flatten(extractChildren(x) || [], extractChildren, (level || 1) + 1, x.id)
    )
  );

export const extractChildren = (x: any) => x.children;

export const flat = (root: any) =>
  flatten(extractChildren(root), extractChildren).map(
    (x) => delete x.children && x
  );
